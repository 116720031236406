/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SmallButton } from '../../../../components/Button';
import { EmptyEventCard } from '../../../../components/EventElements';
import { SchedulesListItem } from '../../../../components/ListItens';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import useAuth from '../../../../hooks/auth';
import useEvent from '../../../../hooks/event';
import useAxiosGet from '../../../../hooks/useAxiosGet';
import ImgBoxMessage from '../../../../images/box-message.png';
import { BoxScroll } from '../../../../styles/globalStyles';
import theme from '../../../../theme';
import convertCurrencyPtBr from '../../../../utils/convertCurrencyPtBr';
import sortedSchedules from '../../../../utils/sortedSchedules';
import { LogoImage } from '../../../Clinic/Dashboard/styles';
import { MDFontDark, PayOutContainer, SMFont } from '../../Finance/styles';
import {
  CardInfoClinic,
  ContainerImage,
  ContainerImageLogo,
  Finances,
  GrossMonthlyRevenue,
  Image,
  Main,
  MainDashboard,
  ModalContainer,
  MonthlyConsultations,
  NotificateAvatarModal,
  ProfileWrapper,
  QueriesWrapper,
  ScheduleWrapper,
  TextAlert,
  TextNotification,
  WrapperFinances,
  WrapperNotification,
} from './styles';

export default function DashboardFree() {
  const { user } = useAuth();
  // const termId = !user?.term?.id || user?.term?.id === 1;
  // const showOnboarding = termId === false && user?.welcome === false;
  const { notificateAvatar, notifications, setNotificateAvatar, setEvents } =
    useEvent();
  const history = useHistory();
  const [loading] = useState(false);

  const getSchedules = useAxiosGet();
  const getSchedulesPast = useAxiosGet();

  const handleModal = () => {
    setNotificateAvatar('no');
    history.push('/professional/config');
  };

  // const handleModalOnboard = async () => {
  //   setLoading(true);
  //   try {
  //     await updateProfessional({ welcome: true }, user?.id);
  //   } catch (error) {
  //     toast.error('Não foi possível atualizar o usuário');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const { controller, data, errors, fetchData } = getSchedules;
    if (data?.length === 0) fetchData(`/${user.type}s/schedules`);
    else setEvents(data);

    if (errors) toast.error('Não foi possível carregar os agendamentos');

    return () => controller.abort();
  }, [getSchedules.loading]);

  useEffect(() => {
    const { controller, data, errors, fetchData } = getSchedulesPast;
    if (data?.length === 0) fetchData(`/${user.type}s/schedules?past=true`);

    if (errors)
      toast.error('Não foi possível carregar os agendamentos concluidos');

    return () => controller.abort();
  }, [getSchedulesPast.loading]);

  useEffect(() => {
    const verifyAvatar = () => {
      if (!user.avatar && notificateAvatar === false) {
        setNotificateAvatar('yes');
      }
    };
    verifyAvatar();
  }, []);

  const scheduledAppointments = getSchedules?.data?.map((schedule) => schedule);

  const consultationsCarriedOutFilter = getSchedulesPast?.data?.filter(
    (schedule) =>
      moment(schedule.attributes?.startDate, 'YYYY-MM-DD').format('MM') ===
      moment().format('MM')
  );

  const schedules = [
    ...scheduledAppointments,
    ...consultationsCarriedOutFilter,
  ];

  const fundsReached = schedules?.reduce((acc, curr) => {
    if (curr.attributes?.paymentStatus === 'paid') {
      return acc + Number(curr.attributes?.paymentAmount);
    }
    return acc;
  }, 0);

  const fundsAnticipated = schedules?.reduce(
    (acc, curr) => acc + Number(curr.attributes?.paymentAmount),
    0
  );

  const percentage =
    (100 /
      (scheduledAppointments?.length + consultationsCarriedOutFilter?.length)) *
    consultationsCarriedOutFilter?.length;

  const newNotifications = notifications.data?.filter(
    (item) =>
      item.attributes?.notificationType === 'message' &&
      item.attributes?.readed === null
  )?.length;

  const message = newNotifications === 0 ? 'nova mensagem' : 'novas mensagens';

  return loading ? (
    <LoaderSpinner />
  ) : (
    <Main>
      <MainDashboard>
        <ProfileWrapper>
          <WrapperNotification
            message={newNotifications === 0 ? 'false' : 'true'}
            onClick={() =>
              newNotifications !== 0 && history.push('/professional/messages')
            }
            order={user?.clinic?.id ? 1 : 2}
          >
            <TextNotification>
              {newNotifications === 0
                ? `Você não tem nenhuma ${message}`
                : `Você tem ${newNotifications} ${message}`}
            </TextNotification>
            <ContainerImage>
              <Image size="100px" src={ImgBoxMessage} />
            </ContainerImage>
          </WrapperNotification>
          {user?.clinic?.id ? (
            <CardInfoClinic>
              {user?.clinic?.banner && (
                <ContainerImageLogo
                  style={{
                    marginBottom: '0.5rem',
                    width: '100%',
                  }}
                >
                  <LogoImage src={user?.clinic?.avatar} alt="Banner" />
                </ContainerImageLogo>
              )}
              <h1 style={{ marginBottom: '0.5rem' }}>{user?.clinic?.name}</h1>
              <p>{user?.clinic?.phone}</p>
            </CardInfoClinic>
          ) : (
            <Finances>
              {getSchedules.loading || getSchedulesPast.loading ? (
                <LoaderSpinner />
              ) : (
                <WrapperFinances>
                  <h3 style={{ marginBottom: '0.7rem' }}>Consultas Mensais</h3>
                  <MonthlyConsultations>
                    <div style={{ justifyContent: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <MDFontDark
                          style={{
                            alignSelf: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          {consultationsCarriedOutFilter?.length}
                        </MDFontDark>
                        <MDFontDark
                          style={{
                            alignSelf: 'center',
                            color: '#426AFF',
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                          }}
                        >
                          {!percentage ? '0' : percentage.toFixed(0)}%
                        </MDFontDark>
                      </div>
                      <label
                        style={{
                          alignSelf: 'center',
                          color: '#040817',
                          fontSize: '12px',
                          lineHeight: '21px',
                          fontWeight: '400',
                        }}
                      >
                        Consultas Realizadas
                      </label>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <MDFontDark
                        style={{
                          alignSelf: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {scheduledAppointments?.length +
                          consultationsCarriedOutFilter?.length}
                      </MDFontDark>
                      <label
                        style={{
                          alignSelf: 'center',
                          color: '#040817',
                          fontSize: '12px',
                          lineHeight: '21px',
                          fontWeight: '400',
                        }}
                      >
                        Consultas Agendadas
                      </label>
                    </div>
                  </MonthlyConsultations>
                </WrapperFinances>
              )}
              {getSchedules.loading || getSchedulesPast.loading ? (
                <LoaderSpinner />
              ) : (
                <WrapperFinances>
                  <h3 style={{ marginBottom: '0.7rem' }}>
                    Receita Mensal Bruta
                  </h3>
                  <GrossMonthlyRevenue>
                    <PayOutContainer>
                      <MDFontDark
                        style={{
                          alignSelf: 'center',
                          color: '#0C1B5C',
                        }}
                      >
                        {convertCurrencyPtBr(fundsReached)}
                      </MDFontDark>
                      <label
                        style={{
                          alignSelf: 'center',
                          color: '#040817',
                          fontSize: '14px',
                          lineHeight: '21px',
                          fontWeight: '400',
                        }}
                      >
                        Receita atingida
                      </label>
                    </PayOutContainer>
                    <PayOutContainer>
                      <MDFontDark
                        style={{
                          alignSelf: 'center',
                          color: '#0C1B5C',
                        }}
                      >
                        {convertCurrencyPtBr(fundsAnticipated)}
                      </MDFontDark>
                      <SMFont style={{ alignSelf: 'center', color: '#040817' }}>
                        Receita prevista
                      </SMFont>
                    </PayOutContainer>
                  </GrossMonthlyRevenue>
                  <p
                    style={{
                      fontSize: '0.7rem',
                      marginTop: '0.5rem',
                      width: '100%',
                    }}
                  >
                    *Taxas de serviço e de planos não inclusas, valores finais
                    podem divergir.
                  </p>
                </WrapperFinances>
              )}
            </Finances>
          )}
        </ProfileWrapper>
        <ScheduleWrapper>
          <QueriesWrapper>
            <h2 className="query-title">
              Últimas consultas em {moment().format('MMMM [de] YYYY')}
            </h2>
            <BoxScroll>
              {getSchedulesPast.loading ? (
                <LoaderSpinner />
              ) : consultationsCarriedOutFilter?.length === 0 ? (
                <EmptyEventCard type="realizados neste mês" past />
              ) : (
                sortedSchedules(consultationsCarriedOutFilter, true)?.map(
                  (item) => (
                    <SchedulesListItem
                      key={item.id}
                      data={item}
                      schedule="true"
                      past
                    />
                  )
                )
              )}
            </BoxScroll>
          </QueriesWrapper>
          <QueriesWrapper past={'true'}>
            <h2 className="query-title">
              Próximas consultas {moment().format('[de] YYYY')}
            </h2>
            <BoxScroll>
              {getSchedules.loading ? (
                <LoaderSpinner />
              ) : scheduledAppointments?.length === 0 ? (
                <EmptyEventCard type="agendados para este mês" />
              ) : (
                sortedSchedules(scheduledAppointments)?.map((item) => (
                  <SchedulesListItem
                    key={item.id}
                    data={item}
                    schedule="true"
                  />
                ))
              )}
            </BoxScroll>
          </QueriesWrapper>
        </ScheduleWrapper>
      </MainDashboard>

      <NotificateAvatarModal
        isOpen={notificateAvatar === 'yes' ? true : false}
        style={{
          overlay: { backgroundColor: `${theme.offWhite}`, opacity: '0.93' },
        }}
      >
        <ModalContainer>
          <TextAlert>
            Ops... parece que você não tem foto de perfil, clique em continuar
            para adicionar uma
          </TextAlert>
          <SmallButton
            width="40%"
            height="40px"
            color="#426AFF"
            type="button"
            title="Continuar"
            onClick={() => handleModal()}
          />
        </ModalContainer>
      </NotificateAvatarModal>

      {/* {showOnboarding && (
        <OnBoardingProfessional handleModal={handleModalOnboard} />
      )} */}
    </Main>
  );
}
