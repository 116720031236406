/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderSpinner from '../../../components/LoaderSpinner';
import { SMFontBackground } from '../../../components/MessageElements/styles';
import { UserAvatar, handleUserNames } from '../../../components/UserElements';
import useAuth from '../../../hooks/auth';
import useEvent from '../../../hooks/event';
import ClinicLogo from '../../../images/logo-clinic-default.png';
import LogoClinic from '../../../images/logo-default-clinic.svg';
import Profile from '../../../images/noAvatar.png';
import api, { apiPublic } from '../../../services/api';
import { PtBr } from '../../../services/pt_br';
import { ButtonScrollToTop, ScrollToTop } from '../../../styles/globalStyles';
import convertCurrencyPtBr from '../../../utils/convertCurrencyPtBr';
import getProfessionName from '../../../utils/getProfessionName';
import { getProfessions } from '../../../utils/getProfessionsFilter';
import isFilterDataInputCompare from '../../../utils/isFilterDataInputCompare';
import { ClinicImageInBanner } from '../../Clinic/AllProfessionals/styles';
import { ArtClinic, BannerImage } from '../../Clinic/Dashboard/styles';
import { BackgroundBanner } from '../../Clinic/Profile/styles';
import { ProfessionalFilter } from '../Professionals/components';
import {
  AvatarCard,
  BodyDescription,
  BodyTitle,
  Button,
  ClinicImage,
  ColumnTextContent,
  ColumnTextTitle,
  ContainerClinic,
  ContainerImage,
  ContainerResponsible,
  DescriptionWrapper,
  DescriptionWrapperResponsible,
  EmptyContainer,
  EmptyImg,
  EmptyLabel,
  FilterButtonContainer,
  ListGrid,
  ListProfessionalContainer,
  LogoClinicContainer,
  LogoImage,
  MBFontSecondary,
  MainContainer,
  MedalIcon,
  ProfessionalCard,
  ProfessionalFilterButton,
  PublicListBody,
  PublicSearchContainer,
  ResponsibleAbout,
  ResponsibleAvatar,
  ResponsibleName,
  RowTextCenter,
  RowTextSpaced,
  SMFontDark,
  SMFontPrimary,
} from './styles';

function EmptyList({ ...props }) {
  const { text } = props;
  return (
    <EmptyContainer>
      <EmptyLabel>{text}</EmptyLabel>
      <EmptyImg />
    </EmptyContainer>
  );
}

export function Professional({ ...props }) {
  const { scheduleInfo, setScheduleInfo } = useEvent();
  const history = useHistory();
  const { professional, id } = props;
  const type = localStorage.getItem('type');
  const [auth] = useState(type === 'client' ? 'cliente' : 'publica');

  function getNextSchedule(professional) {
    let today = new Date();
    let todayWeekDay = today.getDay();
    if (professional?.availabilityRule?.workdays) {
      let nextDay = professional?.availabilityRule?.workdays?.filter(
        (day) => parseInt(day, 10) > todayWeekDay
      );
      if (nextDay?.length === 0) {
        nextDay = professional?.availabilityRule?.workdays[0];
        today.setDate(
          today.getDate() + (6 - todayWeekDay + (parseInt(nextDay, 10) + 1))
        );
      } else {
        today.setDate(
          today.getDate() + (parseInt(nextDay[0], 10) - todayWeekDay)
        );
      }
    }
    return today;
  }

  return (
    <ProfessionalCard>
      <AvatarCard>
        <UserAvatar src={professional.avatar || Profile} search />
      </AvatarCard>
      {professional?.clinicId && (
        <LogoClinicContainer>
          <LogoImage src={professional?.clinic?.avatar || ClinicLogo} />
        </LogoClinicContainer>
      )}
      <ColumnTextTitle>
        <MBFontSecondary>{handleUserNames(professional)}</MBFontSecondary>
        <SMFontDark>{`${getProfessionName(professional.profession, 'toPt')} ${
          professional.address.city ? `| ${professional.address.city}` : ''
        }`}</SMFontDark>
      </ColumnTextTitle>
      <ColumnTextContent>
        <RowTextCenter>
          <SMFontPrimary style={{ fontWeight: '400' }}>
            Próxima data:{' '}
          </SMFontPrimary>
          <SMFontPrimary>{`${
            PtBr.days_abs[getNextSchedule(professional).getDay()]
          }, ${moment(getNextSchedule(professional), 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          )}`}</SMFontPrimary>
        </RowTextCenter>
        <RowTextCenter>
          {professional?.specialities?.map((item, index) =>
            index <= 3 ? (
              <SMFontBackground key={item}>{item}</SMFontBackground>
            ) : null
          )}
          {professional?.specialities?.length > 4 && (
            <Tooltip title={professional?.specialities?.slice(4).join(', ')}>
              <SMFontBackground>
                {`+${professional?.specialities?.length - 4}`}
              </SMFontBackground>
            </Tooltip>
          )}
        </RowTextCenter>
        <RowTextSpaced>
          <RowTextCenter style={{ width: '40%' }}>
            <SMFontPrimary style={{ fontSize: '1.1rem' }}>
              {convertCurrencyPtBr(professional?.sessionPrice)}
            </SMFontPrimary>
            <SMFontDark style={{ fontSize: '1.1rem' }}>{`por ${
              professional?.availabilityRule?.appointmentDuration || '30'
            } min`}</SMFontDark>
          </RowTextCenter>
          <RowTextCenter className="evaluate">
            <SMFontDark style={{ fontSize: '1.1rem', marginRight: '0.325rem' }}>
              {professional.likes || '0'} avaliações
            </SMFontDark>
            {!professional?.clinicId && professional.acceptPromotion && (
              <Tooltip title="Esse profissional participa de promoções Allminds">
                <MedalIcon />
              </Tooltip>
            )}
          </RowTextCenter>
        </RowTextSpaced>
        {professional?.clinicId && (
          <RowTextCenter>
            <SMFontPrimary>
              Esse profissional faz parte da clinica
            </SMFontPrimary>
            <SMFontDark>{professional?.clinic?.name}</SMFontDark>
          </RowTextCenter>
        )}
      </ColumnTextContent>
      <RowTextCenter
        style={{
          justifyContent: 'center',
          margin: '2rem 0 2rem 0',
          paddingLeft: '0',
        }}
      >
        <Button
          onClick={() => {
            if (scheduleInfo?.professional?.id !== id) setScheduleInfo(null);
            if (type === 'client') history.push(`/client/schedule/${id}`);
            else history.push(`/${auth}/agendamento/${id}`);
          }}
        >
          Agendar
        </Button>
      </RowTextCenter>
    </ProfessionalCard>
  );
}

export default function ProfessionalsClinic() {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { eventFilter, setEventFilter, setCities } = useEvent();
  const [loading, setLoading] = useState(true);
  const [professions, setProfessions] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [professionalsFilter, setProfessionalsFilter] = useState([]);
  const [clinic, setClinic] = useState({});
  const [toggleFilter, setToggleFilter] = useState(false);
  const scrollRef = useRef(null);

  const getCities = (professionalsFilter) => {
    const citys = professionalsFilter?.reduce((acc, curr) => {
      if (curr?.attributes.address.city === null) return acc;
      if (acc.includes(curr?.attributes?.address?.city.toLowerCase().trim()))
        return acc;
      acc.push(curr?.attributes?.address?.city.toLowerCase().trim());
      return acc;
    }, []);

    const parseCities = citys?.map((city) => city.toLowerCase()).sort();

    return parseCities;
  };

  useEffect(() => {
    const validClinic = async () => {
      try {
        const { data } = await apiPublic.get(
          `/public/check_clinic_username?username=${
            location.pathname.split('/')[1]
          }`
        );
        if (data.data) {
          setClinic({ id: data.data.id, ...data?.data?.attributes });
          const { data: professionalsData } = await apiPublic.get(
            `/public/clinic_professionals?username=${
              location.pathname.split('/')[1]
            }`
          );
          if (professionalsData.data) {
            setCities(getCities(professionalsData.data));
            setProfessionals(professionalsData.data);
            setProfessionalsFilter(professionalsData.data);
          } else {
            throw new Error('Profissionais não encontrada');
          }
        } else {
          throw new Error('Clinica não encontrada');
        }
      } catch (error) {
        toast.error('Clinica não encontrada');
        if (user?.id) {
          history.push('/client/dashboard');
        } else {
          history.push('/publica/profissionais');
        }
      } finally {
        setLoading(false);
      }
    };

    validClinic();

    const fetchProfessions = async () => {
      try {
        const { data } = await api.get('/professions');
        const professionList = getProfessions(
          data?.data?.attributes?.professions
        );
        setProfessions(professionList);
      } catch (error) {
        toast.error('Não foi possível buscar profissões');
      }
    };
    fetchProfessions();
  }, [location.pathname]);

  function scrollToTop() {
    return scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function resetFilters() {
    setProfessionalsFilter(professionals);
    setEventFilter({
      address: '',
      approaches: [],
      labels: [],
      price: '0',
      professions: [],
      professionFilter: [],
      reasons: [],
      search: '',
      sessionType: [],
      specialities: [],
    });
  }

  function isEqual(a, b, type) {
    if (b?.length === 0 || b === '' || b === 0 || b === null) return true;
    if (type === 1) return a?.toLowerCase()?.includes(b?.toLowerCase());
    if (type === 2) return filterProfessions(a, b);
    return FilterArray(a, b);
  }

  function isDefault(filter) {
    let isDefaultValue = true;
    Object.keys(filter).forEach((key) => {
      if (
        filter[`${key}`]?.length !== 0 &&
        filter[`${key}`] !== '' &&
        filter[`${key}`] !== 0 &&
        filter[`${key}`] !== null
      ) {
        isDefaultValue = false;
      }
    });
    return isDefaultValue;
  }

  function FilterArray(a, b) {
    let tmp = [];
    tmp = b?.filter((item) => a?.includes(item));

    return tmp?.length > 0;
  }

  function filterProfessions(a, b) {
    let tmp = [];

    tmp = b?.filter((item) => a?.includes(item));
    return tmp?.length > 0;
  }

  function filterProfessionalAdvisor(filter) {
    let profTmp = [];
    if (filter?.professionFilter?.includes('professional_advisor')) {
      profTmp = professionals?.filter(
        (item) =>
          (item.attributes?.reasons?.includes('Orientação profissional') ||
            item.attributes?.approaches?.includes('Orientação profissional') ||
            item.attributes?.specialities?.includes(
              'Orientação profissional'
            )) &&
          item
      );
    }
    return profTmp;
  }

  function filterAddress(filter) {
    const address =
      filter.address === 'todas as cidades' ||
      filter.address === undefined ||
      filter.address === null
        ? (filter.address = '')
        : filter.address;
    return address;
  }

  const filterProfessionals = async (filter) => {
    const address = filterAddress(filter);

    const professionalAdvisor = eventFilter.professionFilter.includes(
      'professional_advisor'
    )
      ? filterProfessionalAdvisor(filter)
      : [];

    const data = professionals?.filter(
      (item) =>
        isDefault(filter) ||
        (isEqual(
          item.attributes.firstName + item.attributes.lastName,
          filter.search,
          1
        ) &&
          isEqual(item.attributes.profession, filter.professionFilter, 2) &&
          isEqual(
            item.attributes.availabilityRule.appointmentTypes,
            filter.sessionType,
            3
          ) &&
          isEqual(item.attributes.address.city, address, 1) &&
          isEqual(item.attributes.specialities, filter.specialities, 0) &&
          isEqual(item.attributes.reasons, filter.reasons, 0) &&
          isEqual(item.attributes.approaches, filter.approaches, 0) &&
          parseInt(item.attributes.sessionPrice, 10) >= filter.price)
    );

    !isFilterDataInputCompare(eventFilter)
      ? setProfessionalsFilter([...data, ...professionalAdvisor])
      : setProfessionalsFilter(null);
  };

  const professionalsData = useMemo(() => {
    if (!professionalsFilter) return professionals;
    return professionalsFilter;
  }, [professionalsFilter, professionals]);

  const hiddenFields = [
    'description',
    'responsibleAvatar',
    'responsibleName',
    'responsibleAbout',
  ];

  const hiddenFieldsResponsible = [
    'responsibleAvatar',
    'responsibleName',
    'responsibleAbout',
  ];

  return loading ? (
    <LoaderSpinner logo />
  ) : (
    <MainContainer style={{ padding: '2rem' }}>
      <ContainerImage>
        {!clinic?.hiddenFields.includes('banner') ? (
          clinic?.banner ? (
            <BannerImage src={clinic?.banner} alt="Banner" />
          ) : (
            <BackgroundBanner color={clinic?.bannerColor} />
          )
        ) : (
          <div style={{ height: '13rem' }} />
        )}
        {!clinic?.banner && (
          <ClinicImageInBanner src={clinic?.avatar || LogoClinic} />
        )}
      </ContainerImage>

      <ContainerClinic>
        <ClinicImage src={clinic?.avatar || LogoClinic} />

        {!clinic?.hiddenFields.includes('description') && (
          <DescriptionWrapper>
            <BodyTitle style={{ fontSize: '1.5rem' }}>{clinic?.name}</BodyTitle>
            <BodyTitle>Sobre nós:</BodyTitle>
            <BodyDescription>{clinic?.description}</BodyDescription>
          </DescriptionWrapper>
        )}
      </ContainerClinic>

      <ContainerResponsible>
        {!hiddenFields.every((field) => clinic?.hiddenFields.includes(field)) &&
          !hiddenFieldsResponsible.every((field) =>
            clinic.hiddenFields.includes(field)
          ) && (
            <DescriptionWrapperResponsible>
              {!clinic?.hiddenFields.includes('responsibleName') && (
                <ResponsibleName style={{ fontSize: '1.5rem' }}>
                  {clinic?.responsibleName}
                </ResponsibleName>
              )}
              <ResponsibleName
                style={{
                  fontWeight: 'normal',
                  color: '#0C1B5C',
                  marginBottom: '0',
                }}
              >
                Responsável Técnico
              </ResponsibleName>
              {!clinic?.hiddenFields.includes('responsibleDocumentNumber') && (
                <ResponsibleName
                  style={{ color: '#0C1B5C', fontWeight: 'normal' }}
                >
                  CRP: {clinic?.responsibleDocumentNumber}
                </ResponsibleName>
              )}
              {!clinic?.hiddenFields.includes('responsibleAbout') && (
                <ResponsibleAbout>{clinic?.responsibleAbout}</ResponsibleAbout>
              )}
            </DescriptionWrapperResponsible>
          )}

        {!clinic?.hiddenFields.includes('responsibleAvatar') && (
          <ResponsibleAvatar src={clinic?.responsibleAvatar || Profile} />
        )}
      </ContainerResponsible>

      {clinic?.secondaryBanner && <ArtClinic src={clinic?.secondaryBanner} />}

      <PublicListBody ref={scrollRef}>
        <PublicSearchContainer>
          <p
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              marginBottom: '0.5rem',
            }}
          >
            Nossos Profissionais:
          </p>
          <FilterButtonContainer>
            <ProfessionalFilterButton
              onClick={() => setToggleFilter(!toggleFilter)}
            >
              {toggleFilter ? 'Esconder Filtros' : 'Mostrar Filtros'}
              {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </ProfessionalFilterButton>
          </FilterButtonContainer>
          {toggleFilter && (
            <ProfessionalFilter
              onSubmit={filterProfessionals}
              professions={professions}
              resetFilters={resetFilters}
              loading={loading}
            />
          )}
        </PublicSearchContainer>

        <ListProfessionalContainer>
          {professionalsData?.length === 0 ? (
            <EmptyList text="Nenhum profissional foi encontrado. Tente alterar os filtros de busca!" />
          ) : (
            <ListGrid>
              {professionalsData?.map((prof) => (
                <Professional
                  clinic={clinic}
                  id={prof.id}
                  key={prof.id}
                  professional={prof.attributes}
                  search
                />
              ))}
            </ListGrid>
          )}
          {!loading && (
            <Tooltip title="Voltar ao topo">
              <ButtonScrollToTop onClick={scrollToTop}>
                <ScrollToTop />
              </ButtonScrollToTop>
            </Tooltip>
          )}
        </ListProfessionalContainer>
      </PublicListBody>
    </MainContainer>
  );
}
